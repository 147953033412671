<template>
	<div class="createParks">
		<!-- <div class="main-filter">
			<el-form inline :model="queryData" class="demo-form-inline search-form">
				<el-form-item label="合同编号：">
					<el-autocomplete v-model.trim="queryData.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 1 条结果</p>
				<!-- <el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button> -->
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:table-head="tableHead"
				:is-show-selection="false"
				:operation-column-width="80"
				:showOperation="true"
				:isShowPage="false"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 上传 </el-button>
				</template>
				<template #serviceNotice="{ scope }">
					<el-button
						@click="showPdf(scope.row, 'serviceNotice')"
						size="mini"
						type="text"
						:style="{ color: scope.row.serviceNotice.length == 0 ? '#333' : '#1db9b1' }"
						:disabled="scope.row.serviceNotice.length == 0"
					>
						查看({{ scope.row.serviceNotice.length }})
					</el-button>
				</template>
				<!-- 插槽操作栏固定属性operation -->
				<template #serviceContentAndTerms="{ scope }">
					<el-button
						@click="showPdf(scope.row, 'serviceContentAndTerms')"
						size="mini"
						type="text"
						:disabled="scope.row.serviceContentAndTerms.length == 0"
						:style="{ color: scope.row.serviceContentAndTerms.length == 0 ? '#333' : '#1db9b1' }"
					>
						查看({{ scope.row.serviceContentAndTerms.length }})
					</el-button>
				</template>
			</Table>
		</div>
		<createView @getList="getList" ref="createView" />
		<el-dialog title="查看pdf文件" :visible.sync="pdfVisible" width="800px" center top="5vh">
			<iframe class="pdfClass" ref="mainiframe" :src="viewUrlPdf" style="width: 100%" :style="{ height: screenHeight + 'px' }"></iframe>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'contractManager',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			loading: false,
			tableData: [],
			tableHead: [
				{
					label: '县域名称',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '服务须知',
					prop: 'serviceNotice',
				},
				{
					label: '服务内容及条款',
					prop: 'serviceContentAndTerms',
				},
			],
			link: '',
			userInfo: {},
			viewUrlPdf: '',
			pdfVisible: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 160;
		},
		screenHeight() {
			return document.body.clientHeight - 240;
		},
	},
	mounted() {
		this.getList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
	},
	methods: {
		showPdf(item, type) {
			this.pdfVisible = true;
			this.viewUrlPdf = item[type][0].link;
		},
		//获取列表
		getList() {
			this.$http
				.get(this.api['HealthServiceTerms#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						if (res.data.collection.length == 0) {
							res.data.collection.push({
								name:
									this.userInfo?.chinaRegionDtoList[0]?.title +
										this.userInfo?.chinaRegionDtoList[1]?.title +
										this.userInfo?.chinaRegionDtoList[2]?.title || '-',
								serviceNotice: [],
								serviceContentAndTerms: [],
							});
						} else {
							res.data.collection = res.data.collection.map((item) => {
								return {
									serviceNotice: item.attachments.filter((v) => v.subjectTypeCode == 'HEALTH_SERVICE_INSTRUCTION'),
									serviceContentAndTerms: item.attachments.filter((v) => v.subjectTypeCode == 'HEALTH_SERVICE_TERM'),
									name:
										this.userInfo?.chinaRegionDtoList[0]?.title +
											this.userInfo?.chinaRegionDtoList[1]?.title +
											this.userInfo?.chinaRegionDtoList[2]?.title || '-',
									_links: item._links,
									id: item.id,
								};
							});
						}
						this.tableData = res.data.collection || [];
						this.link = res.data._links.create.href;
						this.$forceUpdate();
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(row) {
			let data = {
				link: !row.id ? this.link : row._links.update.href,
				row: row.id ? row : {},
			};
			this.$refs.createView.init(data);
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	padding-bottom: 10px;
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #1db9b1;
}
</style>
